<template>
  <div class="dashboard">
    <!-- <NavBar /> -->
    <div class="font-weight-bold mt-5 mb-5" style="font-size: 24px">
      Telecommunication Infrastructure
    </div>
    <v-row>
      <v-col v-if="show_transmission == false" cols="2" class="d-flex">
        <v-select
          @input="changeOpsel"
          v-model="selected_opsel"
          :items="item_opsel"
          item-text="name"
          item-value="id"
          :item-disabled="checkIsItemDisabled"
          solo
          rounded
          dense
          placeholder="Network Operator"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-select
          @input="changeTech"
          v-model="selected_tech"
          :disabled="disabled_tech"
          :background-color="disabled_tech ? 'grey lighten-3' : ''"
          :items="technology"
          item-text="name"
          item-value="id"
          :item-disabled="checkIsItemDisabled"
          solo
          rounded
          dense
          placeholder="2G"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-btn
          :disabled="disabled_filter"
          :background-color="disabled_filter ? 'grey lighten-3' : ''"
          elevation="0"
          rounded
          class="text-capitalize control-btn mr-5"
          outlined
          @click="showModal"
          >Filter <v-icon>mdi-filter-outline</v-icon>
        </v-btn>
        <v-btn
          rounded
          elevation="0"
          color="#169CD6"
          class="mr-5 text-capitalize white--text"
          >Submit</v-btn
        >
        <v-btn
          @click="resetFilter"
          rounded
          elevation="0"
          class="text-capitalize"
          >Reset</v-btn
        >
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2" class="d-flex justify-end">
        <v-btn
          elevation="0"
          rounded
          class="text-capitalize control-btn"
          outlined
          >Download <v-icon>mdi-file-download-outline</v-icon>
        </v-btn>
      </v-col>

      <v-col lg="6">
        <v-card elevation="5" class="pa-5">
          <v-skeleton-loader
            v-if="skeleton_loading"
            type="image"
          ></v-skeleton-loader>
          <ColumnChart v-else :series="series" />
        </v-card>
      </v-col>
      <v-col lg="6">
        <v-card elevation="5" class="pa-5">
          <v-skeleton-loader
            v-if="skeleton_loading"
            type="image"
          ></v-skeleton-loader>
          <CoverageAdminChart v-else :series="series_coverage" />
        </v-card>
      </v-col>
      <v-col v-if="show_transmission == false" lg="12">
        <v-card elevation="5" class="pa-5 map-card">
          <VueLayersMaps />
        </v-card>
      </v-col>
      <v-col v-if="show_transmission" lg="12">
        <v-row>
          <v-col lg="8">
            <v-card elevation="5" class="pa-5 map-card">
              <v-skeleton-loader
                v-if="skeleton_loading"
                type="image"
              ></v-skeleton-loader>
              <VueLayersMaps v-else :height="height" :id="id" />
            </v-card>
          </v-col>
          <v-col lg="4">
            <v-card elevation="5" class="pa-5">
              <v-skeleton-loader
                v-if="skeleton_loading"
                type="image"
              ></v-skeleton-loader>
              <div v-else>
                <div class="text-center font-weight-bold">
                  Daftar Fix Broadband
                </div>
                <v-text-field
                  prepend-inner-icon="mdi-magnify"
                  dense
                  outlined
                  class="mt-5"
                  rounded
                  placeholder="Pencarian"
                ></v-text-field>
                <div class="d-flex">
                  <div>Penyedia Layanan</div>
                  <v-spacer></v-spacer>
                  <div class="d-flex">
                    <div>Pilih Semua</div>
                    <v-checkbox dense></v-checkbox>
                  </div>
                </div>
                <div class="d-flex mt-4" v-for="i in 6" :key="i">
                  <div>Telkom Indonesia - IndiHome</div>
                  <v-spacer></v-spacer>
                  <v-checkbox dense></v-checkbox>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <component
      :is="selected_modal"
      :coverage="coverage"
      :coverage_bts="coverage_bts"
      :bts="bts"
      :transmission="transmission"
      v-on:closeCoverage="closeCoverage"
      v-on:closeCoverageBts="closeCoverageBts"
      v-on:closeBts="closeBts"
      v-on:closeTransmission="closeTransmission"
    />
  </div>
</template>

<script>
// import NavBar from "../../components/dashboard/NavBar.vue";
import JwtService from "../../../services/jwt.services";
import VueLayersMaps from "../../../components/dashboard/TelcoInfraMaps.vue";
import ColumnChart from "../../../components/dashboard/ColumnChart.vue";
import CoverageAdminChart from "../../../components/dashboard/CoverageAdminChart.vue";
import CoverageModal from "../../../components/dashboard/modal/CoverageModal.vue";
import CoverageBtsModal from "../../../components/dashboard/modal/CoverageBtsModal.vue";
import BtsModal from "../../../components/dashboard/modal/BtsModal.vue";
import TransmissionModal from "../../../components/dashboard/modal/TransmissionModal.vue";
import { GET_OPSEL } from "../../../store/modules/opsel.module";

export default {
  components: {
    VueLayersMaps,
    ColumnChart,
    CoverageModal,
    CoverageAdminChart,
  },
  data: () => ({
    series: [],
    series_coverage: [],
    height: "580px",
    id: "mapRounded",
    selected_tower: 0,
    selected_opsel: 0,
    show_transmission: false,
    skeleton_loading: true,
    selected_modal: "",
    coverage: false,
    coverage_bts: false,
    bts: false,
    transmission: false,
    telco_type: [],
    user_token: null,
    opsel: [
      {
        name: "Network Operator",
        id: 0,
      },
    ],
    technology: [
      {
        name: "Technology",
        id: 0,
      },
      {
        name: "2G",
        id: 1,
      },
      {
        name: "3G",
        id: 2,
      },
      {
        name: "4G",
        id: 3,
      },
    ],
    selected_tech: 0,
    disabled_opsel: true,
    disabled_tech: true,
    disabled_filter: true,
    map_layers: [],
    user: null,
    layer: [],
  }),

  computed: {
    item_opsel: function () {
      return this.$store.getters.item_opsel;
    },
  },
  methods: {
    changeView(value) {
      console.log(value);
      this.layer = value;
      if (value == "Fixed Broadband") {
        this.show_transmission = true;
        console.log(this.show_transmission);
        setTimeout(() => {
          this.skeleton_loading = false;
        }, 3000);
      } else {
        (this.disabled_opsel = false), (this.show_transmission = false);
      }
    },

    getOperator() {
      this.$store.dispatch(GET_OPSEL);
    },

    getMapLayer() {
      fetch(
        process.env.VUE_APP_API_URL +
          `api/v1/${this.user.language}/map-layer/category`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.user_token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.error == true) {
            console.log(result.message);
          } else {
            this.map_layers = result.data;
            console.log(this.map_layers);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showModal() {
      this.selected_modal = CoverageModal;
      this.coverage = true;
      switch (this.selected_tower) {
        case 1:
          {
            this.selected_modal = CoverageModal;
            this.coverage = true;
          }
          break;
        case 2:
          {
            this.selected_modal = CoverageBtsModal;
            this.coverage_bts = true;
          }
          break;
        case 3:
          {
            this.selected_modal = BtsModal;
            this.bts = true;
          }
          break;
        case 4:
          {
            this.selected_modal = TransmissionModal;
            this.transmission = true;
          }
          break;
      }
    },

    checkIsItemDisabled(item) {
      return item.id === 0;
    },

    closeCoverage(value) {
      this.coverage = value;
    },
    closeCoverageBts(value) {
      this.coverage_bts = value;
    },
    closeBts(value) {
      this.bts = value;
    },
    closeTransmission(value) {
      this.transmission = value;
    },

    changeOpsel() {
      if (this.selected_opsel != 0) {
        this.disabled_tech = false;
      }
    },

    changeTech() {
      if (this.selected_tech != 0) {
        this.disabled_filter = false;
      }
    },

    resetFilter() {
      this.selected_opsel = 0;
      this.selected_tech = 0;
    },

    getCoverageArea() {
      const url =
        "https://infragis.kominfo.go.id/webapi/graph/coveragearea?data=ALL";

      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.skeleton_loading = false;
          let very_good = [];
          let good = [];
          let fair = [];
          let bad = [];

          let series = [
            {
              name: "Baik Sekali",
              data: very_good,
            },
            {
              name: "Baik",
              data: good,
            },
            {
              name: "Cukup",
              data: fair,
            },
            {
              name: "Kurang",
              data: bad,
            },
          ];
          for (let i = 0; i < result.length; i++) {
            console.log(i);
            very_good[i] = result[i]["luas"]["Baik Sekali"];
            good[i] = result[i]["luas"]["Baik"];
            fair[i] = result[i]["luas"]["Cukup"];
            bad[i] = result[i]["luas"]["Kurang"];
          }

          this.series = series;
          console.log(this.series);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCoverageAdminArea() {
      const url =
        "https://infragis.kominfo.go.id/webapi/graph/coverageadmin?data=ALL";

      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.skeleton_loading = false;
          let province = [];
          let city = [];
          let district = [];
          let sub_district = [];

          let series = [
            {
              name: "Province",
              data: province,
            },
            {
              name: "Kota",
              data: city,
            },
            {
              name: "Kecamatan",
              data: district,
            },
            {
              name: "Kelurahan",
              data: sub_district,
            },
          ];
          for (let i = 0; i < result.length; i++) {
            console.log(i);
            province[i] = result[i]["jumlah_tercover"]["Provinsi"];
            city[i] = result[i]["jumlah_tercover"]["Kota"];
            district[i] = result[i]["jumlah_tercover"]["Kecamatan"];
            sub_district[i] = result[i]["jumlah_tercover"]["Kelurahan"];
          }

          this.series_coverage = series;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    this.user_token = JwtService.getToken();
    this.user = JwtService.getUser();

    this.getOperator();
    this.getMapLayer();
    this.getCoverageArea();
    this.getCoverageAdminArea();
  },
};
</script>

<style scped>
.map-card {
  border-radius: 20px;
}

.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.v-sheet.v-card {
  border-radius: 10px;
}

.control-btn {
  color: #5e5873 !important;
}

.alarm-item {
  margin-top: 13.7px;
  margin-bottom: 13.7px;
}

.miniBarProgress {
  background-color: #8a898a;
  height: 100%;
  position: absolute;
  top: 0rem;
  border-radius: 20px;
  left: 0rem;
}

.miniBar {
  height: 20px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  position: relative;
  width: -webkit-calc(100% - 2rem);
  width: -moz-calc(100% - 2rem);
  width: calc(100% - 2rem);
  margin-right: 0.5rem;
}

.v-progress-circular__underlay {
  stroke: #ebf57f !important;
}

.kecamatan .v-progress-circular__underlay {
  stroke: #a7dc65 !important;
}

.kabupaten .v-progress-circular__underlay {
  stroke: #65dc9a !important;
}

.province .v-progress-circular__underlay {
  stroke: #28c76f !important;
}

.v-progress-circular__info {
  color: black;
}
</style>
